import { ActionFactoryParams } from '../../../../utilsDeprecated/ControlledComponent/ControlledComponent.types';
import { CalendarStateDeprecated } from '../../controller';
import { CalendarContext } from '../../../../utilsDeprecated/context/contextFactory';
import { getEndOfMonthAsLocalDateTime } from '../../../../utilsDeprecated/dateAndTime/dateAndTime';
import { TriggeredByOptions } from '../../../../types/types';
import { sendDatePickerLoadedBiEvent } from '../../../../utilsDeprecated/bi/events/events';

export type SetSelectedMonth = (
  localDateTime: string,
  triggeredBy: TriggeredByOptions,
) => Promise<void>;

export function createSetSelectedMonthAction({
  getControllerState,
  context,
}: ActionFactoryParams<calendarstatedeprecated, CalendarContext="">): SetSelectedMonth {
  return async (localDateTime: string, triggeredBy: TriggeredByOptions) => {
    const [state, setState] = getControllerState();
    const { calendarApi, biLogger } = context;
    const endOfMonthAsLocalDateTime = getEndOfMonthAsLocalDateTime(
      localDateTime,
      1,
    );
    const selectedRange = {
      from: localDateTime,
      to: endOfMonthAsLocalDateTime,
    };
    setState({
      selectedRange,
    });

    const availableSlotsPerDay = await calendarApi.getDateAvailability(
      {
        fromAsLocalDateTime: localDateTime,
        toAsLocalDateTime: endOfMonthAsLocalDateTime,
      },
      { state, context },
    );

    setState({
      availableSlotsPerDay,
    });

    sendDatePickerLoadedBiEvent({
      biLogger,
      availableSlotsPerDay,
      triggeredBy,
    });
  };
}
</calendarstatedeprecated,></void>