import { ViewModelFactoryParams } from '../../../../utilsDeprecated/ControlledComponent/ControlledComponent.types';
import { CalendarStateDeprecated } from '../../controller';
import { CalendarContext } from '../../../../utilsDeprecated/context/contextFactory';
import { MemoizedViewModalFactory } from '../viewModel';
import {
  createWeeklyTimeSlotsLayoutViewModel,
  memoizedWeeklyTimeSlotsLayoutViewModel,
} from '../weeklyTimeSlotsLayoutViewModel/weeklyTimeSlotsLayoutViewModel';
import {
  createWeeklyTimetableLayoutViewModel,
  memoizedWeeklyTimetableLayoutViewModel,
} from '../weeklyTimetableLayoutViewModel/weeklyTimetableLayoutViewModel';
import {
  createDailyTimeSlotsLayoutViewModel,
  memoizedDailyTimeSlotsLayoutViewModel,
} from '../dailyTimeSlotsLayoutViewModel/dailyTimeSlotsLayoutViewModel';
import {
  isDailyTimeSlotsWeeklyPickerLayout,
  isDailyAgendaWeeklyPickerLayout,
  isWeeklyTimeSlotsLayout,
  isWeeklyTimetableLayout,
} from '../../../../utilsDeprecated/layouts';
import { BodyViewModel } from './bodyViewModel.types';
import {
  createDailyAgendaWeeklyPickerLayoutViewModel,
  memoizedDailyAgendaWeeklyPickerLayoutViewModel,
} from '../dailyAgendaWeeklyPickerLayoutViewModel/dailyAgendaWeeklyPickerLayoutViewModel';

export const memoizedBodyViewModelViewModel: MemoizedViewModalFactory<bodyviewmodel> =
  {
    dependencies: {
      settings: ['calendarLayout'],
      subDependencies: [
        memoizedDailyTimeSlotsLayoutViewModel.dependencies,
        memoizedWeeklyTimeSlotsLayoutViewModel.dependencies,
        memoizedWeeklyTimetableLayoutViewModel.dependencies,
        memoizedDailyAgendaWeeklyPickerLayoutViewModel.dependencies,
      ],
    },
    createViewModel: createBodyViewModel,
  };

export function createBodyViewModel({
  state,
  context,
}: ViewModelFactoryParams<calendarstatedeprecated, CalendarContext="">): BodyViewModel {
  const { settings, settingsParams } = context;

  if (isWeeklyTimeSlotsLayout(settings, settingsParams)) {
    return {
      weeklyTimeSlotsLayoutViewModel: createWeeklyTimeSlotsLayoutViewModel({
        state,
        context,
      }),
    };
  }

  if (isWeeklyTimetableLayout(settings, settingsParams)) {
    return {
      weeklyTimetableLayoutViewModel: createWeeklyTimetableLayoutViewModel({
        state,
        context,
      }),
    };
  }

  if (isDailyAgendaWeeklyPickerLayout(settings, settingsParams)) {
    return {
      dailyAgendaWeeklyPickerLayoutViewModel:
        createDailyAgendaWeeklyPickerLayoutViewModel({ state, context }),
    };
  }

  const dailyTimeSlotsLayoutViewModel = createDailyTimeSlotsLayoutViewModel({
    state,
    context,
  });

  if (isDailyTimeSlotsWeeklyPickerLayout(settings, settingsParams)) {
    return {
      dailyTimeSlotsWeeklyPickerLayoutViewModel: dailyTimeSlotsLayoutViewModel,
    };
  }

  return {
    dailyTimeSlotsMonthlyPickerLayoutViewModel: dailyTimeSlotsLayoutViewModel,
  };
}
</calendarstatedeprecated,></bodyviewmodel>