import { ViewModelFactoryParams } from '../../../../utilsDeprecated/ControlledComponent/ControlledComponent.types';
import { CalendarStateDeprecated } from '../../controller';
import { CalendarContext } from '../../../../utilsDeprecated/context/contextFactory';
import { getLocalTimezone } from '../../../../utilsDeprecated/dateAndTime/dateAndTime';
import { BusinessInfoBase } from '@wix/bookings-uou-types';
import { Optional } from '../../../../types/types';
import { MemoizedViewModalFactory } from '../viewModel';

export type TimezoneSelectionViewModel = {
  selectableTimezones: string[];
  selectedTimezone: string;
  timezoneLabel: string;
  timezoneLocale: string;
};

export const memoizedTimezoneSelectionViewModel: MemoizedViewModalFactory<
  Optional<timezoneselectionviewmodel>
> = {
  dependencies: {
    state: ['selectedTimezone'],
  },
  createViewModel: createTimezoneSelectionViewModel,
};

export function createTimezoneSelectionViewModel({
  context,
  state,
}: ViewModelFactoryParams<
  CalendarStateDeprecated,
  CalendarContext
>): Optional<timezoneselectionviewmodel> {
  const { selectedTimezone } = state;
  const { businessInfo, t, reportError } = context;

  const locale = businessInfo!.dateRegionalSettingsLocale!;

  try {
    const selectableTimezones = getSelectableTimezones(businessInfo!);

    return {
      selectableTimezones,
      selectedTimezone: selectedTimezone!,
      timezoneLabel: t('app.timezone-selection.timezone-label'),
      timezoneLocale: locale,
    };
  } catch (e) {
    reportError(e as string | Error);
  }
}

function getSelectableTimezones(businessInfo: BusinessInfoBase): string[] {
  if (businessInfo!.timezoneProperties?.clientCanChooseTimezone) {
    return [businessInfo!.timeZone!, getLocalTimezone()];
  }
  return [];
}
</timezoneselectionviewmodel></timezoneselectionviewmodel>