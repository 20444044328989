import { ActionFactoryParams } from '../../../../utilsDeprecated/ControlledComponent/ControlledComponent.types';
import { CalendarStateDeprecated } from '../../controller';
import { CalendarContext } from '../../../../utilsDeprecated/context/contextFactory';
import { WidgetComponents, WidgetElements } from '../../../../utilsDeprecated/bi/consts';
import { bookingsCalendarClick } from '@wix/bi-logger-wixboost-ugc/v2';

export type OnElementClicked = (
  component: WidgetComponents,
  element: WidgetElements,
  rawProperties?: any,
) => void;

export function createOnElementClicked({
  context: { biLogger },
}: ActionFactoryParams<calendarstatedeprecated, CalendarContext="">): OnElementClicked {
  return (component, element, rawProperties) => {
    let properties;
    if (rawProperties) {
      if (typeof rawProperties === 'object') {
        properties = JSON.stringify(rawProperties);
      } else {
        properties = rawProperties.toString();
      }
    }
    biLogger.report(
      bookingsCalendarClick({
        component,
        element,
        properties,
      }),
    );
  };
}
</calendarstatedeprecated,>