import { ActionFactoryParams } from '../../../../utilsDeprecated/ControlledComponent/ControlledComponent.types';
import { CalendarContext } from '../../../../utilsDeprecated/context/contextFactory';
import { CalendarStateDeprecated } from '../../controller';

export type CloseDialogAction = () => void;

export function createCloseDialogAction({
  getControllerState,
}: ActionFactoryParams<calendarstatedeprecated, CalendarContext="">): CloseDialogAction {
  return () => {
    const [, setState] = getControllerState();

    setState({
      dialog: undefined,
    });
  };
}
</calendarstatedeprecated,>