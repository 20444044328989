import { ViewModelFactoryParams } from '../../../../utilsDeprecated/ControlledComponent/ControlledComponent.types';
import { CalendarContext } from '../../../../utilsDeprecated/context/contextFactory';
import { CalendarStateDeprecated } from '../../controller';
import { MemoizedViewModalFactory } from '../viewModel';
import { Optional } from '../../../../types/types';
import { isCalendarPage } from '../../../../utilsDeprecated/presets';
import { isBackFromFormWithCart } from '../../../../utilsDeprecated/queryParams/queryParams';

export type BackButtonViewModel = {
  label: string;
  tooltipText?: string;
};

export const memoizedBackButtonViewModel: MemoizedViewModalFactory<
  Optional<backbuttonviewmodel>
> = {
  dependencies: {
    state: ['rescheduleBookingDetails'],
  },
  createViewModel: createBackButtonViewModel,
};

export function createBackButtonViewModel({
  state,
  context,
}: ViewModelFactoryParams<
  CalendarStateDeprecated,
  CalendarContext
>): Optional<backbuttonviewmodel> {
  const {
    t,
    preset,
    hasReferralParam,
    flowAPI: {
      environment,
      controllerConfig: { wixCodeApi },
    },
  } = context;
  const { rescheduleBookingDetails } = state;

  const isOnEditorMode = environment.isPreview || environment.isEditor;

  if (
    isBackFromFormWithCart(wixCodeApi) ||
    !isCalendarPage(preset) ||
    rescheduleBookingDetails ||
    (!isOnEditorMode && !hasReferralParam)
  ) {
    return;
  }

  return {
    label: t('app.calendar.back'),
    tooltipText: isOnEditorMode
      ? t('app.preview.tooltips.back-button')
      : undefined,
  };
}
</backbuttonviewmodel></backbuttonviewmodel>