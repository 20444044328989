import { CalendarContext } from '../../../../utilsDeprecated/context/contextFactory';
import { ActionFactoryParams } from '../../../../utilsDeprecated/ControlledComponent/ControlledComponent.types';
import { currentLanguage } from '../../../../utilsDeprecated/currentLanguage/currentLanguage';
import { CalendarStateDeprecated } from '../../controller';

export type OnLoginClick = () => void;

export function createOnLoginClickAction({
  context: {
    flowAPI: {
      environment,
      controllerConfig: { wixCodeApi },
    },
  },
}: ActionFactoryParams<calendarstatedeprecated, CalendarContext="">): OnLoginClick {
  return () => {
    if (!environment.isPreview) {
      wixCodeApi.user.promptLogin({
        mode: 'login',
        lang: currentLanguage(wixCodeApi),
      });
    }
  };
}
</calendarstatedeprecated,>