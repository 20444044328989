import { ViewModelFactoryParams } from '../../../../utilsDeprecated/ControlledComponent/ControlledComponent.types';
import { CalendarStateDeprecated } from '../../controller';
import { CalendarContext } from '../../../../utilsDeprecated/context/contextFactory';
import {
  CalendarErrors,
  FilterOptions,
  Optional,
} from '../../../../types/types';
import * as _ from 'lodash';
import { MemoizedViewModalFactory } from '../viewModel';
import { isDailyAgendaWeeklyPickerLayout } from '../../../../utilsDeprecated/layouts';
import { FilterTypes } from '../filterViewModel/filterViewModel';

export type NoAvailableSlotsViewModel = {
  noSessionsOfferedText: string;
  noUpcomingTimeSlotsText?: string;
  checkAvailabilityCTA?: {
    text: string;
    isFullWidth: boolean;
  };
};

export const memoizedNoAvailableSlotsViewModel: MemoizedViewModalFactory<noavailableslotsviewmodel> =
  {
    dependencies: {
      state: ['calendarErrors', 'filterOptions'],
      settings: [
        'noSessionsOffered',
        'goToNextAvailableDate',
        'noUpcomingTimeSlots',
        'buttonsFullWidth',
      ],
    },
    createViewModel: createNoAvailableSlotsViewModel,
  };

export function createNoAvailableSlotsViewModel({
  state,
  context,
}: ViewModelFactoryParams<
  CalendarStateDeprecated,
  CalendarContext
>): NoAvailableSlotsViewModel {
  const { getContent, settings, settingsParams } = context;
  const { calendarErrors, filterOptions } = state;
  const isDailyAgendaWithWeeklyPicker = isDailyAgendaWeeklyPickerLayout(
    settings,
    settingsParams,
  );

  const noSessionsOfferedText = getContent({
    settingsParam: settingsParams.noSessionsOffered,
    translationKey: isDailyAgendaWithWeeklyPicker
      ? 'app.settings.defaults.slots.no-availability'
      : 'app.settings.defaults.time-picker.no-slots-message',
  });

  const isNoNextAvailableDate = calendarErrors.some(
    (error) => error === CalendarErrors.NO_NEXT_AVAILABLE_DATE_WARNING,
  );
  const isNextAvailableDateServerError = calendarErrors.some(
    (error) => error === CalendarErrors.NEXT_AVAILABLE_DATE_SERVER_ERROR,
  );

  const noUpcomingTimeSlotsText = getNoUpcomingTimeSlotsText({
    isNoNextAvailableDate,
    filterOptions,
    context,
  });

  const showCheckAvailabilityCTA =
    !isNoNextAvailableDate && !isNextAvailableDateServerError;

  const checkAvailabilityCTA = showCheckAvailabilityCTA
    ? {
        text: getContent({
          settingsParam: settingsParams.goToNextAvailableDate,
          translationKey:
            'app.settings.defaults.time-picker.go-to-next-available-day',
        }),
        isFullWidth: settings.get(settingsParams.buttonsFullWidth),
      }
    : undefined;

  return {
    noSessionsOfferedText,
    noUpcomingTimeSlotsText,
    checkAvailabilityCTA,
  };
}

const isAllVisibleFiltersSelected = ({
  context,
  filterOptions,
}: {
  filterOptions: FilterOptions;
  context: CalendarContext;
}): boolean => {
  const { settings, settingsParams } = context;

  const filtersVisibility: Record<filtertypes, boolean=""> = {
    [FilterTypes.LOCATION]: settings.get(
      settingsParams.headerLocationFilterVisibility,
    ),
    [FilterTypes.SERVICE]: settings.get(
      settingsParams.headerServiceFilterVisibility,
    ),
    [FilterTypes.STAFF_MEMBER]: settings.get(
      settingsParams.headerStaffFilterVisibility,
    ),
  };

  const visibleFilterOptions: Partial<filteroptions> = _.pickBy(
    filterOptions,
    (_value, filterType) => filtersVisibility[filterType as FilterTypes],
  );

  return _.every(visibleFilterOptions, 'length');
};

function getNoUpcomingTimeSlotsText({
  isNoNextAvailableDate,
  filterOptions,
  context,
}: {
  isNoNextAvailableDate: boolean;
  filterOptions: FilterOptions;
  context: CalendarContext;
}): Optional<string> {
  const { getContent, t, settingsParams } = context;

  if (isNoNextAvailableDate) {
    const isUserAppliedAllFilters = isAllVisibleFiltersSelected({
      context,
      filterOptions,
    });

    if (isUserAppliedAllFilters) {
      return t(
        'app.time-picker.notifications.no-next-available-date-matching-filters',
      );
    }

    return getContent({
      settingsParam: settingsParams.noUpcomingTimeSlots,
      translationKey: 'app.time-picker.notifications.no-upcoming-slots',
    });
  }
}
</string></filteroptions></filtertypes,></noavailableslotsviewmodel>