import { ViewModelFactoryParams } from '../../../../utilsDeprecated/ControlledComponent/ControlledComponent.types';
import { CalendarContext } from '../../../../utilsDeprecated/context/contextFactory';
import { CalendarStateDeprecated } from '../../controller';
import { MemoizedViewModalFactory } from '../viewModel';
import { Optional } from '../../../../types/types';

export type LoginViewModel = {
  content: string;
  cta: string;
};

export const memoizedLoginViewModel: MemoizedViewModalFactory<
  Optional<loginviewmodel>
> = {
  dependencies: {
    state: ['servicesInView', 'isUserLoggedIn'],
  },
  createViewModel: createLoginViewModel,
};

export function createLoginViewModel({
  state,
  context,
}: ViewModelFactoryParams<
  CalendarStateDeprecated,
  CalendarContext
>): Optional<loginviewmodel> {
  const { t, isMemberAreaInstalled } = context;
  const { servicesInView, isUserLoggedIn } = state;

  const isServiceHasPricingPlans =
    !!servicesInView[0].payment.pricingPlanInfo?.pricingPlans?.length;
  const shouldShowLoginButton =
    !isUserLoggedIn && isServiceHasPricingPlans && isMemberAreaInstalled;
  if (shouldShowLoginButton) {
    return {
      content: t('app.already-a-member'),
      cta: t('app.login'),
    };
  }
}
</loginviewmodel></loginviewmodel>