import { ActionFactoryParams } from '../../../../utilsDeprecated/ControlledComponent/ControlledComponent.types';
import { CalendarStateDeprecated } from '../../controller';
import { CalendarContext } from '../../../../utilsDeprecated/context/contextFactory';
import { SlotsStatus, TriggeredByOptions } from '../../../../types/types';
import { AddError } from '../addError/addError';
import { SetSelectedTime } from '../setSelectedTime/setSelectedTime';
import { getEndOfDayFromLocalDateTime } from '../../../../utilsDeprecated/dateAndTime/dateAndTime';
import { sendTimePickerLoadedBiEvent } from '../../../../utilsDeprecated/bi/events/events';
import { UpdateAvailabilityPlugin } from '../updateAvailabilityPlugin/updateAvailabilityPlugin';

export type SetSelectedDate = (
  localDateTime: string,
  triggeredBy: TriggeredByOptions,
) => Promise<void>;

export function createSetSelectedDateAction(
  {
    getControllerState,
    context,
  }: ActionFactoryParams<calendarstatedeprecated, CalendarContext="">,
  addError: AddError,
  setSelectedTime: SetSelectedTime,
  updateAvailabilityPlugin: UpdateAvailabilityPlugin,
): SetSelectedDate {
  return async (localDateTime: string, triggeredBy: TriggeredByOptions) => {
    const { calendarApi, biLogger, settings, settingsParams, experiments } =
      context;
    const calendarLayout = settings.get(settingsParams.calendarLayout);
    const [state, setState] = getControllerState();
    const selectedDate = localDateTime;
    setState({
      selectedDate,
      slotsStatus: SlotsStatus.LOADING,
      selectedDateTrigger: triggeredBy,
      selectedVariantsOptions: [],
    });

    if (triggeredBy !== TriggeredByOptions.INITIALIZE_WIDGET) {
      setSelectedTime(undefined, TriggeredByOptions.DATE_SELECTED);
    }

    const endOfSelectedDateAsLocalDateTime =
      getEndOfDayFromLocalDateTime(selectedDate);
    const availableSlots = await calendarApi.getSlotsInRange(
      {
        fromAsLocalDateTime: selectedDate,
        toAsLocalDateTime: endOfSelectedDateAsLocalDateTime,
      },
      {
        state,
        context,
        onError: addError,
      },
    );

    if (selectedDate === getControllerState()[0].selectedDate) {
      setState({ availableSlots });

      if (availableSlots?.availabilityEntries?.length) {
        setState({ slotsStatus: SlotsStatus.AVAILABLE_SLOTS });
      } else {
        setState({
          slotsStatus: SlotsStatus.NO_AVAILABLE_SLOTS,
        });
      }

      sendTimePickerLoadedBiEvent({
        availableSlots,
        triggeredBy,
        selectedDate,
        biLogger,
        calendarLayout,
      });
    }

    experiments.enabled('specs.bookings.calendarPluginUoU') &&
      updateAvailabilityPlugin();
  };
}
</calendarstatedeprecated,></void>