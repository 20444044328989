import { CalendarContext } from '../../../../utilsDeprecated/context/contextFactory';
import { ActionFactoryParams } from '../../../../utilsDeprecated/ControlledComponent/ControlledComponent.types';
import { CalendarStateDeprecated } from '../../controller';
import { IUser } from '@wix/native-components-infra/dist/src/types/types';
import {
  checkForPricingPlanError,
  getValidPurchasedPricingPlansForService,
} from '../../../../utilsDeprecated/pricingPlans/pricingPlans';
import { AddError } from '../addError/addError';

export type OnUserLoggedIn = (user?: IUser) => void;

export function createOnUserLoggedInAction(
  {
    getControllerState,
    context: { calendarApi, experiments },
  }: ActionFactoryParams<calendarstatedeprecated, CalendarContext="">,
  addError: AddError,
): OnUserLoggedIn {
  return async (user) => {
    const [
      { servicesInView, selectedTime, rescheduleBookingDetails },
      setState,
    ] = getControllerState();
    if (user) {
      setState({
        isUserLoggedIn: true,
      });
      const servicePricingPlans =
        (servicesInView.length &&
          servicesInView[0]?.payment.pricingPlanInfo?.pricingPlans!) ||
        [];
      const allPurchasedPricingPlans = user.loggedIn
        ? await calendarApi.getPurchasedPricingPlans({
            currentUser: user,
            service: servicesInView?.[0],
          })
        : [];
      const purchasedPricingPlans = getValidPurchasedPricingPlansForService({
        allPurchasedPricingPlans,
        servicePricingPlans,
      });

      if (selectedTime) {
        const isRescheduleFlow = !!rescheduleBookingDetails;
        const pricingPlanError = checkForPricingPlanError({
          purchasedPricingPlans,
          isRescheduleFlow,
          selectedTime,
        });
        if (pricingPlanError) {
          addError(pricingPlanError);
        }
      }
      setState({
        purchasedPricingPlans,
      });
    }
  };
}
</calendarstatedeprecated,>