import { ActionFactoryParams } from '../../../../utilsDeprecated/ControlledComponent/ControlledComponent.types';
import { CalendarStateDeprecated } from '../../controller';
import { CalendarContext } from '../../../../utilsDeprecated/context/contextFactory';
import { navigateToMembersArea } from '@wix/bookings-booking-navigation';

export type NavigateToMembersArea = () => void;

export function createNavigateToMembersAreaAction({
  context,
}: ActionFactoryParams<calendarstatedeprecated, CalendarContext="">): NavigateToMembersArea {
  return async () => {
    await navigateToMembersArea(context.flowAPI.controllerConfig.wixCodeApi);
  };
}
</calendarstatedeprecated,>